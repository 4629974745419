// eslint-disable-next-line no-restricted-syntax -- old one, can stay for now
'use server'

import { queryEnterpriseCustomerInfoByOrgId } from '@/shared/graphql/queries/enterpriseCustomerInfoByOrgId'
import type { EnterpriseCustomerInfoByOrgIdQuery } from '@/shared/graphql/schema/commonBackend/graphql'
import { logError } from '@/shared/utils/error'
import { UnknownGlobalApiError } from '@/shared/utils/errorClasses'

import type { CustomerInfoBySsnErrorCode } from './types'
import type { GlobalApiResponse } from '../../../shared/utils/globalApiErrorHandler'
import { globalApiErrorHandler } from '../../../shared/utils/globalApiErrorHandler'

/**
 * Server method that return Enterprise Customer information base on the organization id and postal code
 * @param orgId
 * @param postalCode
 * @returns
 */
export const getEnterpriseCustomerInfoById = async (
  orgId: string,
  postalCode: string,
): Promise<
  GlobalApiResponse<
    EnterpriseCustomerInfoByOrgIdQuery['identificationByOrgId'],
    CustomerInfoBySsnErrorCode
  >
> => {
  const { data, error } = await queryEnterpriseCustomerInfoByOrgId({
    orgId,
    postalCode,
  })

  const identificationByOrgId = data?.identificationByOrgId

  if (identificationByOrgId) {
    return { data: identificationByOrgId }
  }

  if (error) {
    return { error: globalApiErrorHandler(error, 'getEnterpriseCustomerInfoById') }
  }
  throw logError(
    new UnknownGlobalApiError('getEnterpriseCustomerInfoById response has no data or error'),
  )
}
