// eslint-disable-next-line no-restricted-syntax -- old one, can stay for now
'use server'

import { queryCustomerInfoByBank } from '@/shared/graphql/queries/customerInfoByBank'
import type { CustomerInfoByBankQuery } from '@/shared/graphql/schema/commonBackend/graphql'
import { logError } from '@/shared/utils/error'
import { UnknownGlobalApiError } from '@/shared/utils/errorClasses'

import type { CustomerInfoByBankErrorCode } from './types'
import type { GlobalApiResponse } from '../../../shared/utils/globalApiErrorHandler'
import { globalApiErrorHandler } from '../../../shared/utils/globalApiErrorHandler'

/**
 * Server method that return Customer information base on the BankId token
 * @param ssn
 * @param postalCode
 * @returns
 */
export const getCustomerInfoByBank = async (
  token: string,
): Promise<
  GlobalApiResponse<CustomerInfoByBankQuery['identificationByBank'], CustomerInfoByBankErrorCode>
> => {
  const { data, error } = await queryCustomerInfoByBank({
    token,
  })

  const identificationByBank = data?.identificationByBank
  if (identificationByBank) {
    return { data: identificationByBank }
  }

  if (error) {
    return { error: globalApiErrorHandler(error, 'getCustomerInfoByBank') }
  }
  throw logError(new UnknownGlobalApiError('getCustomerInfoByBank response has no data or error'))
}
