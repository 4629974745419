// eslint-disable-next-line no-restricted-syntax -- old one, can stay for now
'use server'

import { queryCustomerInfoBySsn } from '@/shared/graphql/queries/customerInfoBySsn'
import type { CustomerInfoBySsnQuery } from '@/shared/graphql/schema/commonBackend/graphql'
import { logError } from '@/shared/utils/error'
import { UnknownGlobalApiError } from '@/shared/utils/errorClasses'

import type { CustomerInfoBySsnErrorCode } from './types'
import type { GlobalApiResponse } from '../../../shared/utils/globalApiErrorHandler'
import { globalApiErrorHandler } from '../../../shared/utils/globalApiErrorHandler'

/**
 * Server method that return Customer information base on the ssn and postal code
 * @param ssn
 * @param postalCode
 * @returns
 */
export const getCustomerInfoBySsn = async (
  ssn: string,
  postalCode: string,
): Promise<
  GlobalApiResponse<CustomerInfoBySsnQuery['identificationBySsn'], CustomerInfoBySsnErrorCode>
> => {
  const { data, error } = await queryCustomerInfoBySsn({
    ssn,
    postalCode,
  })

  const identificationBySsn = data?.identificationBySsn

  if (identificationBySsn) {
    return { data: identificationBySsn }
  }

  if (error) {
    return { error: globalApiErrorHandler(error, 'getCustomerInfoBySsn') }
  }
  throw logError(new UnknownGlobalApiError('getCustomerInfoBySsn response has no data or error'))
}
