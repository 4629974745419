// eslint-disable-next-line no-restricted-syntax -- old one, can stay for now
'use server'

import { cdpClient } from '@/shared/services/clients/cdpClient'
import { logError } from '@/shared/utils/error'
import { NetworkError, UnknownGlobalApiError } from '@/shared/utils/errorClasses'

export type ValidateMembershipResponse = {
  status: boolean
  partnerName: 'coop'
  partnerMembershipId: string
}

/**
 * Validate Coop membership id
 * @param code
 * @returns
 */
export const validateCoopMembershipId = async (code: string) => {
  try {
    const { data } = await cdpClient.get(
      `/gl/v1/bonus/membership/validate?partner=coop&membershipId=${code}&email=`,
    )
    if (isValidateMembershipResponse(data)) {
      return data
    }
    throw logError(new NetworkError('Incompatible data structure received', 400))
  } catch {
    throw logError(new UnknownGlobalApiError('Error when validating coop membership id'))
  }
}

const isValidateMembershipResponse = (data: unknown): data is ValidateMembershipResponse => {
  if (data as Record<string, string>) {
    return Boolean(
      (data as Record<string, unknown>).status !== undefined &&
        (data as Record<string, unknown>).partnerName !== undefined &&
        (data as Record<string, unknown>).partnerMembershipId !== undefined,
    )
  }

  return false
}
