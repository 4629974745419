// eslint-disable-next-line no-restricted-syntax -- old one, can stay for now
'use server'

import { appConfig } from '@/shared/appConfigServer'
import { serverEnvs } from '@/shared/envs'
import { routes } from '@/shared/routes'
import { createAxiosClient } from '@/shared/services/createAxiosClient'

import { withoutTrailingSlash } from '../utils/urlUtils'

type TokenResponse = {
  access_token?: string
  error?: string
  token_type?: string
}

/**
 * Init BankId authentication via ForgeRock, as a result return auth JWT token
 * @param code
 * @returns
 */
export const getBankIdUserToken = async (code: string): Promise<TokenResponse> => {
  const { fetchTokenUrl, clientId } = appConfig.bankId

  const params = new URLSearchParams({
    grant_type: 'authorization_code',
    code,
    client_id: clientId,
    client_secret: serverEnvs.BANKID_PASSTHROUGH_SECRET,
    redirect_uri: `${withoutTrailingSlash(serverEnvs.BASE_URL)}${routes.CHECKOUT}`,
  })

  const { data } = await createAxiosClient().post<TokenResponse>(fetchTokenUrl, params)
  return data
}
