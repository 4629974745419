// eslint-disable-next-line no-restricted-syntax -- old one, can stay for now
'use server'

import { queryAvailableEInvoiceBanks } from '@/shared/graphql/queries/availableEInvoiceBanks'
import type { AvailableEInvoiceBanksQuery } from '@/shared/graphql/schema/commonBackend/graphql'
import { UnknownGlobalApiError } from '@/shared/utils/errorClasses'

import {
  type GlobalApiBaseErrorCode,
  type GlobalApiResponse,
  globalApiErrorHandler,
} from '../../../shared/utils/globalApiErrorHandler'

export const getAvailableEInvoiceBanks = async (): Promise<
  GlobalApiResponse<AvailableEInvoiceBanksQuery['availableEInvoiceBanks'], GlobalApiBaseErrorCode>
> => {
  const { data, error } = await queryAvailableEInvoiceBanks()

  const availableEInvoiceBanks = data?.availableEInvoiceBanks

  if (availableEInvoiceBanks) {
    return {
      data: availableEInvoiceBanks.sort((a, b) =>
        a.bankName.toLowerCase().localeCompare(b.bankName.toLowerCase()),
      ),
    }
  }

  if (error) {
    return { error: globalApiErrorHandler(error, 'getAvailableEInvoiceBanks') }
  }

  // TODO: Should be logServerError but `server-only` throw an error for storybook, something to fix and change
  throw new UnknownGlobalApiError('getAvailableEInvoiceBanks response has no data or error')
}
